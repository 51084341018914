import Joi from "joi"

export const SCHEMA = Joi.object({
  group: Joi.string()
    .min(2)
    .max(64)
    .pattern(/^[a-z0-9_]{2,64}$/)
    .required()
    .messages({
      "string.pattern.base":
        '"Group" name can contain lowercase letters a-z, numbers 0-9, and underscores (_) and be 2-64 characters long',
      "string.empty": "Group is required",
      "string.min": "Group must be at least 2 characters long",
      "string.max": "Group length must be less than or equal to 64 characters long",
    })
    .options({ abortEarly: true }),
  partyConfigurationDomain: Joi.string()
    .domain()
    .allow("")
    .options({ abortEarly: true })
    .message("This field must contain a valid domain name"),
  gdprContact: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .options({ abortEarly: true })
    .messages({
      "string.email": "Must be a valid email address",
      "string.empty": "GDPR contact is required",
    }),
  technicalContact: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .options({ abortEarly: true })
    .messages({
      "string.email": "Must be a valid email address",
      "string.empty": "Technical contact is required",
    }),
}).required()
