import React, { useState } from "react"

import { Icon, IconButton, InfoCard, styled, Submit } from "@ioxio-priv/dataspace-ui"
import Typography from "@mui/material/Typography"
import humanize from "humanize-duration"

import Spinner from "@/components/Spinner"

function Error({ timestamp, error, seen }) {
  const [expanded, setExpanded] = useState(false)
  const date = timestamp.replace(/T/g, " ").replace(/\.\d+Z/g, " UTC")
  const timeDiff = Math.round(new Date(timestamp) - new Date())
  const timeString = humanize(timeDiff, { largest: 1, round: true })

  const title = `${date} (${timeString} ago)`
  return (
    <ErrorWrapper>
      <ErrorHeading>
        <ErrorTitleWrapper>
          {!seen && <Icon name={"error"} color={"error"} />}
          {seen && <IconFiller />}
          <ErrorTitle seen={seen}>{title}</ErrorTitle>
        </ErrorTitleWrapper>
        <IconButton
          icon={expanded ? "arrow-down" : "chevron-right"}
          variant={"outlined"}
          onClick={() => {
            setExpanded(!expanded)
          }}
          color={"secondary"}
        />
      </ErrorHeading>
      {expanded && <ErrorText>{error}</ErrorText>}
    </ErrorWrapper>
  )
}

export default function DataSourceErrors({ loading, errors, lastReadTime, onClick }) {
  function isSeen(ts) {
    if (!lastReadTime) {
      return false
    }
    return new Date(ts) - new Date(lastReadTime) <= 0
  }

  return (
    <div>
      <Divider />
      <Heading>
        <Typography variant={"h4"}>Latest errors</Typography>
        <Submit icon="grading" asyncOnClick={{ asyncFn: onClick }}>
          Mark all as read
        </Submit>
      </Heading>

      <Info>
        <InfoCard>
          Errors are saved only periodically by the Product Gateway. Latest errors are
          not always immediately available, and some errors may be missing.
        </InfoCard>
      </Info>
      <div>
        {loading && <Spinner />}
        {!loading && errors.length === 0 && (
          <NoErrorsWrapper>No errors detected</NoErrorsWrapper>
        )}
        <ErrorsList>
          {errors.map((e) => (
            <Error
              key={e.timestamp}
              timestamp={e.timestamp}
              error={e.error}
              seen={isSeen(e.timestamp)}
            />
          ))}
        </ErrorsList>
      </div>
    </div>
  )
}

const ErrorWrapper = styled("div")`
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.palette.secondary.light};
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ErrorTitle = styled("div")`
  color: ${(p) => (p.seen ? p.theme.palette.neutral.dark : "inherit")};
`

const ErrorTitleWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ErrorHeading = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ErrorText = styled("code")`
  background-color: ${(p) => p.theme.palette.primary.light};
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
  font-size: 0.75rem;
`

const IconFiller = styled("div")`
  width: 1.5rem;
`

const Info = styled("div")`
  margin: 1rem 0;
`

const ErrorsList = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Divider = styled("div")`
  border-bottom: 1px solid ${(p) => p.theme.palette.neutral.main};
  margin: 1.5rem 0;
`

const Heading = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NoErrorsWrapper = styled("div")`
  border: 1px solid ${(p) => p.theme.palette.secondary.light};
  padding: 0.5rem 1rem;
`
