import React from "react"

import { Tooltip } from "@ioxio-priv/dataspace-ui"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

import FormRadioGroup from "@/components/FormRadioGroup"
import { dataSourceListingOptions } from "@/utilities"

export default function SourceState({ form, source }) {
  return (
    <div>
      <Header>
        <span>Visibility</span>
        <Tooltip>LIsting state of the data source.</Tooltip>
      </Header>
      <FormRadioGroup
        form={form}
        name="state"
        baseProps={{
          "data-testid": "state",
        }}
        options={dataSourceListingOptions(source)}
      />
    </div>
  )
}

const Header = styled(Typography)`
  font-weight: 500;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
