import React from "react"

import { IconLink, styled } from "@ioxio-priv/dataspace-ui"
import { Box, Typography } from "@mui/material"

import { Icons } from "@/dsIcon"

export default function DefinitionCard({
  title,
  subtitle,
  description,
  version,
  tags,
}) {
  return (
    <Card>
      <Info data-testid="data-source">
        <Typography variant="h3">{title}</Typography>
        <Description>
          <Typography variant="subtitle1">{subtitle}</Typography>
          <Text>{description}</Text>
        </Description>
        <Footer data-testid="data-source-card-footer">
          {version && (
            <Version data-testid="data-source-version">{`v${version}`}</Version>
          )}
          {tags.length > 0 && <Divider />}
          {tags.map((tag, i) => {
            return (
              <Tag data-testid="data-source-tag" key={tag + `${i}`}>
                {tag}
              </Tag>
            )
          })}
        </Footer>
      </Info>
      <IconLink
        href={`/sources/available/${title}`}
        variant={"outlined"}
        color="secondary"
        icon={Icons.arrowForward}
        baseProps={{
          "data-testid": `view-${title}-sources`,
        }}
      />
    </Card>
  )
}

const Card = styled(Box)`
  padding: 1rem;
  width: 100%;
  border: 1px solid ${(p) => p.theme.palette.secondary.light};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: space-between;
  transition: all 300ms ease-out;

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(16, 24, 40, 0.06);
  }
`

const Info = styled(Box)`
  display: flex;
  flex-direction: column;
`

const Description = styled(Box)`
  margin: 0.5rem 0 1rem;
`

const Text = styled(Typography)`
  font-size: 0.75rem;
  color: ${(p) => p.theme.palette.primary.main};
`

const Footer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
`

const Version = styled(Typography)`
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.palette.secondary.main};
  padding: 3px 5px;
  font-size: 0.75rem;
`

const Divider = styled(Box)`
  border-left: 1px solid ${(p) => p.theme.palette.neutral.main};
`

const Tag = styled(Typography)`
  border-radius: 5px;
  background: ${(p) => p.theme.palette.neutral.main};
  padding: 3px 5px;
  font-size: 0.75rem;
`
