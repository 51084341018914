import Joi from "joi"

import { SourceUrlValidator } from "../../utilities/customValidators"

export const SCHEMA = Joi.object({
  source: Joi.string().required(),
  definition: Joi.string().required(),
  description: Joi.string()
    .label("Description")
    .max(1000)
    .messages({
      "string.max":
        "Description is optional (can be left empty) but when provided, it must be less than or equal to 1000 characters long",
    })
    .allow("")
    .options({ abortEarly: true }),
  baseUrl: SourceUrlValidator.sourceURL()
    .required()
    .label("Base URL")
    .messages({
      "string.empty": "Base URL is required",
      "any.required": "Base URL is required",
      "string.pattern.base": "Base URL must be a valid URL e.g. https://example.com",
    })
    .options({ abortEarly: true }),
  state: Joi.string().valid("unlisted", "private", "published").required(),
  accessControlMode: Joi.string()
    .valid("none", "self_managed", "dataspace_verified")
    .required(),
}).required()
