import React from "react"
import { useForm } from "react-hook-form"

import { joiResolver } from "@hookform/resolvers/joi"
import { IconLink, InfoCard, Link, LinkButton, Submit } from "@ioxio-priv/dataspace-ui"
import { styled, useMediaQuery, useTheme } from "@mui/material"
import Box from "@mui/material/Box"

import { FormBody, FormBox, MailLink } from "@/commonStyles"
import routes from "@/constants/routes"
import { Icons } from "@/dsIcon"

import { getThirdPartyFormData, makeThirdPartySchema } from "./validation"
import Form from "../../components/Form"
import { labels } from "../../constants/labels"
import { config } from "../../settings"
import { toastError } from "../../utilities/errors"
import FormInput from "../FormInput"
import FormSelect from "../FormSelect"

export default function ThirdPartyCreateAppForm({
  groups,
  asyncOnSubmit = async () => ({ ok: true }),
}) {
  const schema = makeThirdPartySchema()
  const form = useForm({
    mode: "onSubmit",
    resolver: joiResolver(schema),
  })

  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

  async function onSubmit(evt) {
    evt.preventDefault()
  }

  async function handleBackendErrors(errors) {
    // `errors` is either a string or an array with FastAPI validation errors
    if (!Array.isArray(errors)) {
      return toastError("Failed to create an application", errors)
    }
    for (let error of errors) {
      const { loc, msg } = error
      const [, field] = loc || []
      form.setError(field, { type: "server", message: msg })
    }
  }

  async function _asyncOnSubmit() {
    const isFormValid = await form.trigger()
    if (isFormValid) {
      const data = getThirdPartyFormData(form)
      return asyncOnSubmit(data, handleBackendErrors)
    }
    return {
      ok: false,
    }
  }

  const _3rdParty = config.externalAppSettings

  const FormContainer = (
    <FormBox>
      <FormBody>
        <FormInput
          name={"name"}
          label={labels.application.fields.name.label}
          form={form}
          placeholder={"Application name"}
          tooltipText={labels.application.fields.name.tooltipText}
          required
        />
        <FormSelect
          form={form}
          name={"group"}
          label={labels.application.fields.group.label}
          placeholder={"Select Group"}
          showAddGroupLink={true}
          options={groups.map((item) => item.group)}
          baseProps={{ "data-testid": "groups-dropdown" }}
          tooltipText={labels.application.fields.group.tooltipText}
          required
        />
      </FormBody>
      <hr />
      <FormBody>
        <FormInput
          name={"appUrl"}
          label={labels.application.fields.website.label}
          placeholder={"https://"}
          tooltipText={labels.application.fields.website.tooltipText}
          form={form}
          type={"url"}
        />
        <FormInput
          name={"privacyPolicy"}
          label={labels.application.fields.privacyPolicy.label}
          placeholder={"https://"}
          tooltipText={labels.application.fields.privacyPolicy.tooltipText}
          form={form}
          type={"url"}
        />
      </FormBody>
      <hr />
      <FormBody>
        <FormInput
          name={"clientID"}
          label={labels.application.fields.clientID.label}
          placeholder={"848a2dba-bfd8-42a8-9e31-7815c5320ca0"}
          form={form}
          tooltipText={labels.application.fields.clientID.tooltipText}
          required
        />
      </FormBody>
    </FormBox>
  )

  return (
    <Wrapper>
      <InfoCard>
        To register your application with this dataspace, please contact{" "}
        {_3rdParty.name} at{" "}
        <MailLink href={`mailto:${_3rdParty.contact}`}>{_3rdParty.contact}</MailLink>{" "}
        and visit{" "}
        <Link href={_3rdParty.registrationURL} target={"_blank"} rel={"noreferrer"}>
          {_3rdParty.registrationURL}
        </Link>{" "}
        for more details. {_3rdParty.name} will provide you with a client ID and secret
        required for access.
      </InfoCard>
      <Form
        onSubmit={onSubmit}
        rightButtons={
          mobileScreen ? (
            <>
              <IconLink
                icon={Icons.cancel}
                href={routes.APPLICATIONS}
                variant={"outlined"}
              />
              <Submit
                icon={Icons.success}
                label={"Create"}
                iconOnly
                color="success"
                asyncOnClick={{
                  asyncFn: _asyncOnSubmit,
                }}
              />
            </>
          ) : (
            <>
              <LinkButton
                variant={"outlined"}
                color={"primary"}
                icon={Icons.cancel}
                href={routes.APPLICATIONS}
              >
                Cancel
              </LinkButton>
              <Submit
                icon={Icons.success}
                color={"success"}
                asyncOnClick={{ asyncFn: _asyncOnSubmit }}
              >
                Create
              </Submit>
            </>
          )
        }
      >
        {FormContainer}
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
`
